import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AuthenticatedState } from './authenticated.state';

function createInitialState(): AuthenticatedState {
  return {
    user: undefined,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'authenticated', resettable: true })
export class AuthenticatedStore extends Store<AuthenticatedState> {
  constructor() {
    super(createInitialState());
  }
}
